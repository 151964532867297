@use 'sass:math';
main {
  display: block;
  background: $white;
  margin: 0;
  padding: math.div($gutter, 2) 0;

  min-height: 50vh;

  @include media(tablet) {
    padding: 15px 0;
    min-height: 74vh;
  }
}

.breadcrumbs {
  display: none;
  color: $black;

  @include media(tablet) {
    display: block;
    margin: 0;
    margin-top: 0;
    margin-bottom: -$gutter * 0.67;
    font-size: 14px;

    .breadcrumbs-list {
      margin: 0;
      padding: 0;
      list-style-type: none;
    }

    .breadcrumbs-link {
      text-decoration: none;
      margin: 0;
      padding: 0;
      list-style-type: none;
    }

    .breadcrumbs-list-item {
      display: inline-block;
      position: relative;
      margin-left: math.div($gutter, 3);
      padding-left: math.div($gutter, 4);
      margin-bottom: math.div($gutter, 6);

      &:before {
        content: "/";
        color: $black;
        display: block;
        position: absolute;
        top: 3px;
        left: -4px;
        margin: auto 0;
      }

      &:first-child {
        margin-left: 0;
        padding-left: 0;

        &:before {
          display: none;
        }
      }
    }
  }
}
.modal{
  display: block;
  height: 100vh;
  background: rgba(0,0,0, 0.7);
  position: absolute;
  top: 0;
  z-index: 9;
  width: 100%;
  overflow: hidden;
}
.modal-dialog{
  margin: auto;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  background: #fff;
  padding: 20px;
  border: 4px solid #000;
  min-width: 400px;
  button{
    margin-left: 0px;
  }
  .heading-large{
    margin-top: 0;
  }
}
.question-four {
  .form-hint{
    margin-bottom: 15px;
  }
  .form-control-1-16{
    width: 51.19px;
  }
  .form-control-1-10{
    width: 85.33px;
  }
  .form-label {
    font-size: 19px!important;
    line-height: 1.31!important;
    margin: 0 0 5px;
  }
  .form-control{
    width: 100%!important;
    height: 40px;
  }
}
#planPathwayDescription{
  .selectOptions{
    button.column-fifth{
      height: 4em!important;
    }
  }
}
.vcShowAuthed{
  display: block;
}
.vcShowAutho{
  .vcShowAuthed{
    display: none;
  }
}
.sign-out-hide{
  #header-navigation-signout{
    visibility: hidden!important;
  }
  .offlineMode{
    visibility: hidden;
  }
  .connection-status.online-mode{
      display: block;
  }
}
.showConnectionBar{
  .offlineMode{
    visibility: visible!important;
  }
  #header-navigation-signout{
    visibility: visible!important;
  }
}
.connection-status.online-mode{
  background: #1d70b8;
  display: none;
}
.connection-status.offline-mode{
  display: none;
  background: #000;
}
.connectionStatus{
  .connection-status.online-mode{
      display: block;
  }
  .connection-status.offline-mode{
    display: none;
    background: #000;
  }
}
.connectionStatusOffline{
  .connection-status.online-mode{
    display: none;
}
.connection-status.offline-mode{
  display: block;
}
}
.connection-status{
  padding: 15px 0px;
  text-align: center;
  margin-top: 10px;
  position: fixed;
  width: 100%;
  color: #fff;
  bottom: 0;
  margin: 0;
  display: none;
  h1{
      margin-bottom: 0!important;
  }
}
#status{
  background-color: #1d70b8;
}
#status1{
  background-color: #000;
}
.govuk-warning-text__icon{
  font-weight: 700;
  box-sizing: border-box;
  display: inline-block;
  left: 0;
  min-width: 35px;
  min-height: 35px;
  margin-top: -7px;
  border: 3px solid #0b0c0c;
  border-radius: 50%;
  color: #fff;
  background: #0b0c0c;
  font-size: 30px;
  line-height: 29px;
  text-align: center;
  user-select: none;
  margin-right: 10px;
  margin-bottom: 10px;
  vertical-align: text-top;
}
